<template>
  <v-container>
    <v-row>
      <v-breadcrumbs large :items="items_nav">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card class="ma-4">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="dataTable"
        :search="search"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:item.data_criacao="{ item }">
            {{ item.data_criacao | dateTime }}
        </template>
        <template v-slot:item.categoria_id="{ item }">
            {{ getCategoria(item.categoria_id) }}
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <v-chip
            color="purple"
            text-color="white"
          >
            {{ item.status.replace("_", " ") }}
          </v-chip>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <v-row>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2 blue darken-3"
                @click="viewUsuario(item.usuario_id)"
              >
                <v-icon small dark>mdi-account-search</v-icon>
              </v-btn>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  green darken-3"
                @click="changeStatus(item, 'aprovar')"
              >
                <v-icon small dark>mdi-check-bold</v-icon>
              </v-btn>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  red darken-3"
                @click="changeStatus(item, 'reprovar')"
              >
                <v-icon small dark>mdi-close-thick</v-icon>
              </v-btn>

          </v-row>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getDataFromApi">Reiniciar</v-btn>
        </template>
      </v-data-table>

     

      <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Realizando operação por favor aguarde...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>

    <v-dialog v-model="dialogChangeStatus" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Alterar Situação
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="col">
              <b>Fornecedor:</b><br />
              {{ itemSelected.nome_empresa }}
            </div>
            <div class="col">
              <b>Data de Cadastro:</b><br />
              {{ itemSelected.data_criacao | dateTime }}
            </div>
          </v-row>
          <br>
          <p>
            Deseja realmente {{ this.itemConfirmStatus }} esse fornecedor?
          </p>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-row
            align="center"
            justify="end"
            class="my-4"
          >
            <v-btn
            class="mx-4"
              color="red darken-3 btn-modal"
              @click="dialogChangeStatus = false"
            >
              Cancelar
            </v-btn>
            <v-btn class="mx-4" color="green darken-1 btn-modal" @click="setModalStatus()">
              Continuar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogViewRepresentante" max-width="600px">
      <v-card>
        <v-card-title class="headline">Representante</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <strong>Nome: </strong> {{representante.nome}}
            </v-col>

            <v-col cols="12" md="6">
              <strong>Email: </strong> {{representante.email}}
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="12">
              <strong>Onde conheceu: </strong> {{representante.onde_conheceu}}
            </v-col>
          </v-row>

           <v-row>
            <v-col cols="12">
              Aceitou a política de privacidade em <strong>{{representante.politica_aceita_em | dateTime}}</strong>
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import database from "@/services/database";
import store from '@/store'

export default {
  name: "Fornecedores-Pendentes",
  data() {
    return {
      items_nav: [
        {
          text: "Sistema",
          disabled: false,
        },
        {
          text: "Fornecedores",
          disabled: true,
        },
        {
          text: "Pendentes",
          disabled: true,
        }
      ],
      loading: true,
      search: "",
      singleSelect: false,
      selected: [],
      itemSelected: {
        nome_empresa: "",
        data_criacao: ""
      },
      representante: {
        nome: "",
        email: "",
        onde_conheceu: "",
        politica_aceita_em: ""
      },
      dialogViewRepresentante: false,
      dialog: false,
      dialogProgress: false,
      dialogEdit: false,
      dialogAddress: false,
      dialogChangeStatus: false,
      dialogConfirmStatus: false,
      itemConfirmStatus: "",
      address: [],
      registers: [],
      headers: [
        { text: "Nome", value: "nome_empresa" },
        { text: "Data Cadastro", value: "data_criacao" },
        { text: "Categoria", value: "categoria_id" },
        /* { text: "Telefone", value: "telefone" }, */
        { text: "UF", value: "uf" },
        { text: "Ações", value: "actions" },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi: async function() {
      this.loading = true;
      var data = await database.getAllFornecedoresPendente();
      this.dataTable = data;
      this.loading = false;
    },
    getCategoria: function(id) {
        return store.getters.getCategoriaById(id).nome;
    },
    changeStatus: function(item, acao) {
      this.itemSelected = item;
      this.itemConfirmStatus = acao;
      this.dialogChangeStatus = true;
    },
    getClassModalStatus: function(status) {
      return this.itemSelected.status == status
        ? "status active"
        : "status";
    },
    setModalStatus: async function() {
      this.dialogChangeStatus = false;
      this.dialogProgress = true;
      let novoStatus = this.itemConfirmStatus === "aprovar" ? "APROVADO" : "REPROVADO";
      let result = await database.updateFornecedorStatus(
        this.itemSelected.id,
        novoStatus
      );
      if (result) {
        this.dialogProgress = false;
        this.getDataFromApi();
      } else {
        this.dialogProgress = false;
        this.showSnackbarError = true;
      }
    },
    viewUsuario: async function(usuarioId) {
      this.dialogProgress = true;
      this.representante = await database.getUsuarioById(usuarioId);
      this.dialogProgress = false;
      this.dialogViewRepresentante = true;
    }
  },
};
</script>
<style scoped>
.status {
  background-color: #c3c3c3;
  color: #272727;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.status:hover {
  background-color: #272727;
  color: #c3c3c3;
}
.status .active {
  background-color: #6A1B9A;
  color: #ffffff;
}
</style>